<template>
  <v-card flat class="px-2">
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Status Pegawai :</td>
                      <td>
                        <v-select
                          :items="statusPegawaiList"
                          outlined
                          dense
                          hide-details
                          v-model="employee.status_peg"
                        ></v-select>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Jenis Pegawai :</td>
                      <td>
                        <v-select
                          :items="jenisPegawaiList"
                          outlined
                          dense
                          hide-details
                          v-model="employee.jenis_peg"
                        ></v-select>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              :xl="employee.status_peg == 'PNS' ? 4 : 6"
              :lg="employee.status_peg == 'PNS' ? 4 : 6"
              :md="employee.status_peg == 'PNS' ? 4 : 6"
              sm="12"
              xs="12"
              class="px-0"
            >
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                CPNS
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">TMT :</td>
                      <td>
                        <v-text-field
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.cpns.tgl_mulai_CPNS"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No SK CPNS :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="employee.cpns.no_sk_CPNS"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tanggal SK :</td>
                      <td>
                        <v-text-field
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.cpns.tgl_sk_CPNS"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
              class="px-0"
              v-show="employee.status_peg == 'PNS'"
            >
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                PNS
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">TMT :</td>
                      <td>
                        <v-text-field
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.pns.tgl_mulai_PNS"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No SK CPNS :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="employee.pns.no_sk_PNS"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tanggal SK :</td>
                      <td>
                        <v-text-field
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.pns.tgl_sk_PNS"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="12"
              :xl="employee.status_peg == 'PNS' ? 4 : 6"
              :lg="employee.status_peg == 'PNS' ? 4 : 6"
              :md="employee.status_peg == 'PNS' ? 4 : 6"
              sm="12"
              xs="12"
              class="px-0 pt-14"
            >
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">PMK :</td>
                      <td>
                        <v-card-actions class="pa-0">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="employee.PMK"
                          ></v-text-field>
                          <span class="px-5">Tahun</span>
                          <v-text-field
                            class="ml-1"
                            outlined
                            dense
                            hide-details
                            v-model="employee.PMK_bulan"
                          ></v-text-field>
                          <span class="px-5">Bulan</span>
                        </v-card-actions>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No Karpeg :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="employee.no_karpeg"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No Karis/SU :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="employee.no_karis_su"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              class="px-0"
            >
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Unit Kerja
              </p>
              <v-simple-table id="table-custom">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Unit Utama :</td>
                    <td>
                      <v-autocomplete
                        :items="unitList"
                        :loading="loadingUnitUtama"
                        outlined
                        dense
                        hide-details
                        v-model="unit_kerja.unit_utama"
                        item-text="singkatan"
                        item-value="unit_utama_id"
                        @change="changeUnit(1)"
                        @click:clear="changeUnit(1)"
                        return-object
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Unit Kerja 2 :</td>
                    <td>
                      <v-autocomplete
                        :items="unit2List"
                        :loading="loadingUnit"
                        outlined
                        dense
                        hide-details
                        v-model="unit_kerja.unit_kerja_2"
                        item-text="singkatan"
                        item-value="unit_kerja_2_id"
                        @change="changeUnit(2)"
                        @click:clear="changeUnit(2)"
                        return-object
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Unit Kerja 3 :</td>
                    <td>
                      <v-autocomplete
                        :items="unit3List"
                        :loading="loadingUnit"
                        outlined
                        dense
                        hide-details
                        v-model="unit_kerja.unit_kerja_3"
                        item-text="singkatan"
                        item-value="unit_kerja_3_id"
                        @change="changeUnit(3)"
                        @click:clear="changeUnit(3)"
                        return-object
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Unit Kerja 4 :</td>
                    <td>
                      <v-autocomplete
                        :items="unit4List"
                        :loading="loadingUnit"
                        outlined
                        dense
                        hide-details
                        v-model="unit_kerja.unit_kerja_4"
                        item-text="singkatan"
                        item-value="unit_kerja_4_id"
                        @change="changeUnit(4)"
                        @click:clear="changeUnit(4)"
                        return-object
                      ></v-autocomplete>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Jabatan dan Eselon
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Jabatan :</td>
                      <td>
                        <v-autocomplete
                          :items="jabatanList"
                          :loading="loading_jabatan"
                          outlined
                          dense
                          hide-details
                          v-model="employee.jabatan_eselon.jabatan"
                          item-text="nama_jabatan"
                          item-value="jabatan_id"
                          return-object
                        ></v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Eselon :</td>
                      <td>
                        <v-autocomplete
                          :items="eselonList"
                          :loading="loading_eselon"
                          outlined
                          dense
                          hide-details
                          v-model="employee.jabatan_eselon.eselon"
                          item-text="keterangan"
                          item-value="eselon_id"
                          return-object
                        ></v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">TMT :</td>
                      <td>
                        <v-text-field
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.jabatan_eselon.tmt_jabatan"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Nomor SK :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="employee.jabatan_eselon.no_sk_jabatan"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tanggal SK :</td>
                      <td>
                        <v-text-field
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.jabatan_eselon.tgl_sk_jabatan"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Golongan dan Kepangkatan
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Golongan :</td>
                      <td>
                        <v-autocomplete
                          :items="golonganList"
                          :loading="loading_golongan"
                          outlined
                          dense
                          hide-details
                          v-model="employee.golongan_pangkat.gol"
                          item-text="gol"
                          item-value="gol"
                          return-object
                        ></v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Pangkat :</td>
                      <td>
                        <v-autocomplete
                          :items="pangkatList"
                          :loading="loading_pangkat"
                          outlined
                          dense
                          hide-details
                          v-model="employee.golongan_pangkat.pangkat"
                          item-text="pangkat"
                          item-value="pangkat_id"
                          return-object
                        ></v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">TMT :</td>
                      <td>
                        <v-text-field
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.golongan_pangkat.tmt_gol"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Nomor SK :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="employee.golongan_pangkat.no_sk_gol"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tanggal SK :</td>
                      <td>
                        <v-text-field
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.golongan_pangkat.tgl_sk_gol"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
              class="px-0 pt-14"
            >
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Status Keaktifan :</td>
                      <td>
                        <v-select
                          :items="statusAktifList"
                          outlined
                          dense
                          hide-details
                          v-model="employee.status_aktif"
                        ></v-select>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapEmployeeRootField } from "@/store/helpers";
import DataUtamaService from "@/services/resources/data.utama.service";

export default {
  data() {
    return {
      visible: false,
      loading_golongan: false,
      loading_eselon: false,
      loading_jabatan: false,
      loading_pangkat: false,
      loadingUnitUtama: false,
      loadingUnit: false,
      statusPegawaiList: ["CPNS", "PNS", "NON-ASN"],
      statusAktifList: ["Aktif", "Berhenti", "Meninggal", "Pensiun", "Pindah"],
      jenisPegawaiList: ["PNS Dipekerjakan", "PNS Diperbantukan", "PNS Pusat"],
      golonganList: [],
      eselonList: [],
      jabatanList: [],
      pangkatList: [],
      unit_utama: [],
      unitList: [],
      unit2List: [],
      unit3List: [],
      unit4List: []
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal",
      employee: "employee",
      unit_kerja: "unit_kerja"
    }),
    getJabatanEmployee() {
      return this.employee.jabatan_eselon.jabatan;
    },
    getKepangkatanEmployee() {
      return this.employee.golongan_pangkat.gol;
    }
  },
  watch: {
    getJabatanEmployee(val) {
      this.employee.jabatan_eselon.eselon = null;
      if (val) {
        this.getEselon(val.jabatan_id);
      }
    },
    getKepangkatanEmployee(val) {
      this.employee.golongan_pangkat.pangkat = null;
      if (val) {
        this.getPangkat(val.gol);
      }
    }
  },
  methods: {
    handleToggle() {
      this.$emit("toggle");
    },
    handlePendidikanDialog() {
      this.$refs.mutasiPendidikan.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        jenjang: this.personal.pendidikan.jenjang,
        bidang_studi: this.personal.pendidikan.bidang_studi,
        nama_sekolah: this.personal.pendidikan.nama_sekolah,
        thn_lulus: this.personal.pendidikan.thn_lulus
      });
    },
    handleGolonganDialog() {
      this.$refs.mutasiGolongan.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        gol: this.employee.golongan_pangkat.gol,
        pangkat: this.employee.golongan_pangkat.pangkat,
        tgl_mulai_PNS: this.employee.pns.tgl_mulai_PNS,
        no_sk_PNS: this.employee.pns.no_sk_PNS,
        tgl_sk_PNS: this.employee.pns.tgl_sk_PNS
      });
    },
    handleSKDialog() {
      this.visible = false;
      this.$refs.mutasiJabatanSK.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        unit_utama: this.unit_kerja.unit_utama,
        unit_kerja_2: this.unit_kerja.unit_kerja_2,
        unit_kerja_3: this.unit_kerja.unit_kerja_3,
        unit_kerja_4: this.unit_kerja.unit_kerja_4,
        jabatan: this.employee.jabatan_eselon.jabatan,
        eselon: this.employee.jabatan_eselon.eselon,
        kelas: this.employee.jabatan_eselon.eselon,
        tgl_mulai_PNS: this.employee.pns.tgl_mulai_PNS,
        no_sk_PNS: this.employee.pns.no_sk_PNS,
        tgl_sk_PNS: this.employee.pns.tgl_sk_PNS
      });
    },
    handleJabatanExchangeDialog() {
      this.visible = false;
      this.$refs.mutasiJabatanUnitKerjaExchange.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        unit_utama: this.unit_kerja.unit_utama,
        unit_kerja_2: this.unit_kerja.unit_kerja_2,
        unit_kerja_3: this.unit_kerja.unit_kerja_3,
        unit_kerja_4: this.unit_kerja.unit_kerja_4,
        jabatan: this.employee.jabatan_eselon.jabatan,
        eselon: this.employee.jabatan_eselon.eselon,
        kelas: this.employee.jabatan_eselon.eselon,
        tgl_mulai_PNS: this.employee.pns.tgl_mulai_PNS,
        no_sk_PNS: this.employee.pns.no_sk_PNS,
        tgl_sk_PNS: this.employee.pns.tgl_sk_PNS
      });
    },
    handleJabatanDialog() {
      this.visible = false;
      this.$refs.mutasiJabatanUnitKerja.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        unit_utama: this.unit_kerja.unit_utama,
        unit_kerja_2: this.unit_kerja.unit_kerja_2,
        unit_kerja_3: this.unit_kerja.unit_kerja_3,
        unit_kerja_4: this.unit_kerja.unit_kerja_4,
        jabatan: this.employee.jabatan_eselon.jabatan,
        eselon: this.employee.jabatan_eselon.eselon,
        kelas: this.employee.jabatan_eselon.eselon,
        tgl_mulai_PNS: this.employee.pns.tgl_mulai_PNS,
        no_sk_PNS: this.employee.pns.no_sk_PNS,
        tgl_sk_PNS: this.employee.pns.tgl_sk_PNS
      });
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.unit2List = this.unitList.find(
          d => d.unit_utama_id == this.unit_kerja.unit_utama
        )?.unit_kerja2;
        this.unit_kerja.unit_kerja_2 = null;
        this.unit_kerja.unit_kerja_3 = null;
        this.unit_kerja.unit_kerja_4 = null;
      } else if (type == 2) {
        this.unit3List = this.unit2List.find(
          d => d.unit_kerja_2_id == this.unit_kerja.unit_kerja_2
        )?.unit_kerja3;
        this.unit_kerja.unit_kerja_3 = null;
        this.unit_kerja.unit_kerja_4 = null;
      } else if (type == 3) {
        this.unit4List = this.unit3List.find(
          d => d.unit_kerja_3_id == this.unit_kerja.unit_kerja_3
        )?.unit_kerja4;
        this.unit_kerja.unit_kerja_4 = null;
      }
    },
    populateUnit() {
      this.unit2List = this.unitList.find(
        d => d.unit_utama_id == this.unit_kerja.unit_utama?.unit_utama_id
      )?.unit_kerja2;
      this.unit3List = this.unit2List.find(
        d => d.unit_kerja_2_id == this.unit_kerja.unit_kerja_2?.unit_kerja_2_id
      )?.unit_kerja3;
      this.unit4List = this.unit3List.find(
        d => d.unit_kerja_3_id == this.unit_kerja.unit_kerja_3?.unit_kerja_3_id
      )?.unit_kerja4;
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unitList = data;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getEselon(jabatan_id) {
      try {
        this.loading_eselon = true;
        await DataUtamaService.getEselonByJabatan(jabatan_id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.eselonList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading_eselon = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getGolongan() {
      try {
        this.loading_golongan = true;
        await DataUtamaService.getGolonganListAll()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.golonganList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading_golongan = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getJabatan() {
      try {
        this.loading_jabatan = true;
        await DataUtamaService.getJabatanList({
          dataPerPage: 1000,
          pageCurrent: 1,
          filter: {
            search: "",
            kategori: "",
            kel_jabatan_id: ""
          },
          orderBy: ["nama_jabatan", "asc"]
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.jabatanList = data.list;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading_jabatan = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getPangkat(gol) {
      try {
        this.loading_pangkat = true;
        await DataUtamaService.getKepangkatanListByGol({ gol })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.pangkatList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading_pangkat = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getGolongan();
    this.getJabatan();
    this.getPangkat(this.employee.golongan_pangkat.gol || "");
    this.getEselon(this.employee.jabatan_eselon.jabatan || "");
    this.getListUnit();
    console.log(this.unit2List);
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.675rem;
  }
}
#table-custom .v-input {
  font-size: 0.675rem;
}
</style>
